<template>
  <div id="brand" v-show="!loading">
    <vs-row class="mb-5">
      <div class="vx-card p-6 mb-5">
        <vs-col vs-w="12" class="mb-5">
          <h3 class="text-xl font-semibold">
            Property Info
          </h3>
        </vs-col>

        <vs-col vs-w="12" class="mb-3">
          <p class="text-md">
            <span class="font-semibold">Name :</span> {{ brand.name }}
          </p>
        </vs-col>

        <vs-col vs-w="12" class="mb-3">
          <p class="text-md">
            <span class="font-semibold">Location :</span>
            {{ brand.location }}
          </p>
        </vs-col>

        <vs-col vs-w="12" class="mb-5">
          <p class="text-md">
            <span class="font-semibold">Address :</span>
            {{ brand.address }}
          </p>
        </vs-col>

        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" @click.prevent="editData"
            >Edit Data</vs-button
          >
        </div>
      </div>
    </vs-row>

<!--    <vs-row class="mb-10">-->
<!--      <vs-col vs-w="12">-->
<!--        <div class="vx-card p-6">-->
<!--          <h3 class="text-xl font-semibold mb-4">-->
<!--            Host type selection-->
<!--          </h3>-->

<!--          <p>-->
<!--            To comply with consumer authority commitments, we need the following-->
<!--            info from partners so we can tell guests whether the property-->
<!--            they're staying at is run by a professional or private host.-->
<!--          </p>-->
<!--          <h5 class="text-md font-semibold my-5">-->
<!--            Which option to decribes you best ?-->
<!--          </h5>-->
<!--          <ul class="leftx">-->
<!--            <li class="mb-5">-->
<!--              <vs-radio-->
<!--                v-model="brandProperty.host_type"-->
<!--                vs-value="professional"-->
<!--              >-->
<!--                <h5 class="text-lg mb-1 ml-2">-->
<!--                  Professional host-->
<!--                </h5>-->
<!--                <p class="ml-2 leading-normal">-->
<!--                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.-->
<!--                </p>-->
<!--              </vs-radio>-->
<!--            </li>-->
<!--            <li>-->
<!--              <vs-radio v-model="brandProperty.host_type" vs-value="private">-->
<!--                <h5 class="text-lg mb-1 ml-2">-->
<!--                  Private / Non - Professional host-->
<!--                </h5>-->
<!--                <p class="ml-2 leading-normal">-->
<!--                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem,-->
<!--                  explicabo? Nulla, vero maiores.-->
<!--                </p>-->
<!--              </vs-radio>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <div class="vx-col w-full mt-5">-->
<!--            <vs-button class="mr-3 mb-2" @click.prevent="storePropertyData"-->
<!--              >Update your info</vs-button-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--      </vs-col>-->
<!--    </vs-row>-->

<!--    <vs-row>-->
<!--      <vs-col vs-w="12">-->
<!--        <div class="vx-card p-6">-->
<!--          <h3 class="text-xl font-semibold mb-4">-->
<!--            Property Management Info-->
<!--          </h3>-->
<!--          <p>-->
<!--            At Vancy Net, we strive to give our partners the best possible-->
<!--            tools, but to do this we need your help. By sharing a few details-->
<!--            about your property and the systems you’re currently using, we can-->
<!--            work to build connections with your providers to create a seamless-->
<!--            experience for you and your guests.-->
<!--          </p>-->
<!--          <p class="mt-3">-->
<!--            It goes without saying, but any data you share with us will be-->
<!--            highly confidential and only be used by us internally to improve our-->
<!--            service to you – it won't be shared outside of Vancy Net.-->
<!--          </p>-->

<!--          <vs-row class="my-5">-->
<!--            <vs-col vs-w="12" class="mb-5">-->
<!--              <span class="text-base font-semibold mb-1 block"-->
<!--                >Which property management system are you using ?</span-->
<!--              >-->
<!--              <vs-input class="w-full" v-model="brandProperty.pms" />-->
<!--              <span class="block text-sm mt-2 text-danger">{{-->
<!--                errors.first("pms")-->
<!--              }}</span>-->
<!--              <vs-checkbox class="mt-3" v-model="brandPropertyCheckbox.pms"-->
<!--                >I don't currently use a PMS.</vs-checkbox-->
<!--              >-->
<!--            </vs-col>-->

<!--            <vs-col vs-w="12" class="mb-3">-->
<!--              <span class="text-base font-semibold mb-1 block"-->
<!--                >Which channel manager or central reservation system are you-->
<!--                using ?</span-->
<!--              >-->
<!--              <vs-input class="w-full" v-model="brandProperty.crs" />-->
<!--              <span class="block text-sm mt-2 text-danger">{{-->
<!--                errors.first("crs")-->
<!--              }}</span>-->
<!--              <vs-checkbox class="mt-3" v-model="brandPropertyCheckbox.crs"-->
<!--                >I don't currently use a channel manager or central reservation-->
<!--                system.</vs-checkbox-->
<!--              >-->
<!--            </vs-col>-->
<!--          </vs-row>-->

<!--          <div class="vx-col w-full mt-5">-->
<!--            <vs-button class="mr-3 mb-2" @click.prevent="storePropertyData"-->
<!--              >Update your info</vs-button-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--      </vs-col>-->
<!--    </vs-row>-->

    <!-- PopUp -->
    <div class="centerx">
      <vs-popup
        title="Edit Property Info"
        :active.sync="popup"
      >
        <vs-row>
          <vs-col vs-w="12" class="mb-3">
            <vs-input class="w-full" label="Name (*)"
                      data-vv-as="Property Name"
                      name="name"
                      v-validate="'required'"
                      v-model="payload.name" />
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("cityId")
              }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Property Type (*)</span>
            <v-select
              label="name"
              data-vv-as="Property Type"
              name="propertyTypeId"
              v-validate="'required'"
              :options="serviceOptions"
              :reduce="service => service.id"
              v-model="payload.propertyTypeId"
            />
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("propertyTypeId")
              }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input class="w-full"
                      label="Star"
                      type="number"
                      max="5"
                      data-vv-as="Star"
                      name="star"
                      v-model="payload.star" />
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("star")
              }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">City (*)</span>
            <v-select
              name="city"
              data-vv-as="City"
              v-validate="'required'"
              :options="cities"
              :reduce="city => city.id"
              v-model="payload.cityId"
            >
              <template #option="{city, province}">
                <p>{{ city }}, {{ province }}</p>
              </template>

              <template #selected-option="{city, province}">
                <p>{{ city }}, {{ province }}</p>
              </template>
            </v-select>
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("cityId")
              }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input class="w-full"
                      label="Latitude (*)"
                      data-vv-as="Latitude"
                      name="latitude"
                      v-validate="'required'"
                      v-model="payload.latitude" />
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("latitude")
              }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input class="w-full"
                      label="Longitude (*)"
                      data-vv-as="Latitude"
                      name="longitude"
                      v-validate="'required'"
                      v-model="payload.longitude" />
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("longitude")
              }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input class="w-full"
                      label="Min Child Age (*)"
                      data-vv-as="Min Child Age"
                      name="minChildAge"
                      v-validate="'required'"
                      v-model="payload.minChildAge" />
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("minChildAge")
              }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input class="w-full"
                      label="Max Child Age (*)"
                      data-vv-as="Max Child Age"
                      name="maxChildAge"
                      v-validate="'required'"
                      v-model="payload.maxChildAge" />
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("maxChildAge")
              }}</span>
          </vs-col>

          <vs-col vs-w="12">
            <span class="block text-sm ml-2 mb-1">Address (*)</span>
            <vs-textarea class="w-full"
                         data-vv-as="Address"
                         name="address"
                         v-validate="'required'"
                         v-model="payload.address"/>
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("address")
              }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3 mb-2">
            <span class="mb-2 block text-sm">Description</span>
            <quill-editor
              v-model="payload.description"
              :options="editorOption"
              @ready="onEditorReady($event)"
              ref="myQuillEditor"
            ></quill-editor>
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("description")
            }}</span>
          </vs-col>
        </vs-row>

        <vs-button icon="save" class="mt-3" @click.prevent="storeData"
          >Save</vs-button
        >
      </vs-popup>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

// Quill Editor Library
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Quill from 'quill';
import VSelect from "vue-select";

export default {
  name: "PropertyGeneralInfo",

  metaInfo: {
    title: "General Info",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    quillEditor,
    VSelect,
  },

  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    hotelId() {
      return this.$store.state.userData().userInfo.hotelId;
    }
  },

  data() {
    return {
      brand: {
        cityId: null,
        propertyTypeId: null,
        name: "",
        address: "",
        location: "",
        description: "",
        minChildAge: "",
        maxChildAge: "",
        latitude: null,
        longitude: null,
        star: null,
      },
      cities: [],
      serviceOptions: [],
      brandProperty: {
        host_type: "",
        crs: "",
        pms: ""
      },
      brandPropertyCheckbox: {
        pms: true,
        crs: true
      },
      payload: {},
      popup: false,
      loading: true,
      editorOption: {
      // some quill options
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
            ["link"]
          ],
        }
      }
      }
    };
  },

  watch: {
    brandProperty: {
      handler(property) {
        property.crs !== ""
          ? (this.brandPropertyCheckbox.crs = false)
          : (this.brandPropertyCheckbox.crs = true);
        property.pms !== ""
          ? (this.brandPropertyCheckbox.pms = false)
          : (this.brandPropertyCheckbox.pms = true);
      },

      deep: true
    },

    brandPropertyCheckbox: {
      handler(checkbox) {
        checkbox.pms == true
          ? (this.brandProperty.pms = "")
          : (this.brandProperty.pms = this.brandProperty.pms);
        checkbox.crs == true
          ? (this.brandProperty.crs = "")
          : (this.brandProperty.crs = this.brandProperty.crs);
      },

      deep: true
    }
  },

  methods: {
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },

    async getData() {
      this.$vs.loading({
        type: 'sound'
      })

      axios
        .get(`hotels/${this.hotelId}`)
        .then(({ data: res }) => {
          this.brand.name = res.data.name;
          this.brand.cityId = res.data.cityId;
          this.brand.propertyTypeId = res.data.propertyTypeId;
          this.brand.address = res.data.address ? res.data.address : "";
          this.brand.location = res.data.city ? `${res.data.city}, ${res.data.province}` : "";
          this.brand.description = res.data.description ? res.data.description : "";
          this.brand.latitude = res.data.latitude;
          this.brand.longitude = res.data.longitude;
          this.brand.minChildAge = res.data.minChildAge;
          this.brand.maxChildAge = res.data.maxChildAge;
          this.brand.star = res.data.star;

          if (res.data.brand_property) {
            this.brandProperty.host_type =
              res.data.brand_property.host_type !== null
                ? res.data.brand_property.host_type
                : "";
            this.brandProperty.pms =
              res.data.brand_property.pms !== null
                ? res.data.brand_property.pms
                : "";
            this.brandProperty.crs =
              res.data.brand_property.crs !== null
                ? res.data.brand_property.crs
                : "";
          }
        })
        .catch(err => this.$catchErrorResponse(err))
        .finally(() => {
          this.loading = false
          this.$vs.loading.close()
        })

      try {
        const { data: res } = await axios.get('cities');
        this.cities = res.data;
      } catch (e) {
        this.$catchErrorResponse(e)
      }

      try {
        const { data: res } = await axios.get('property-types');
        this.serviceOptions = res.data;
      } catch (e) {
        this.$catchErrorResponse(e)
      }
    },

    editData() {
      this.popup = true;

      this.payload = {
        cityId: parseInt(this.brand.cityId),
        propertyTypeId: parseInt(this.brand.propertyTypeId),
        name: this.brand.name,
        address: this.brand.address,
        location: this.brand.location,
        description: this.brand.description,
        minChildAge: parseInt(this.brand.minChildAge),
        maxChildAge: parseInt(this.brand.maxChildAge),
        latitude: parseFloat(this.brand.latitude),
        longitude: parseFloat(this.brand.longitude),
        star: parseInt(this.brand.star),
      };
    },

    async storeData() {
      if (!(await this.$validator.validateAll())) {
        return
      }

      if (!this.$isValidDescription(this.payload.description))
        return

      const payload = {
        ...this.payload,
        cityId: parseInt(this.payload.cityId),
        propertyTypeId: parseInt(this.payload.propertyTypeId),
        minChildAge: parseInt(this.payload.minChildAge),
        maxChildAge: parseInt(this.payload.maxChildAge),
        latitude: parseFloat(this.payload.latitude),
        longitude: parseFloat(this.payload.longitude),
        star: parseInt(this.payload.star),
      }
      axios
        .put(
          `hotels/${this.hotelId}`,
          payload,
        )
        .then(({ data: res }) => {
          this.popup = false;
          this.$vs.notify({
            title: "Success",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.getData();
        })
        .catch(err => this.$catchErrorResponse(err));
    },
  },
  created() {
    this.getData();
  }
};
</script>

<style scoped>
#brand .con-vs-radio,
#brand .con-vs-checkbox {
  display: flex !important;
  align-items: flex-start !important;
}
</style>
